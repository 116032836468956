@import "partials/variables";

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.tab__list {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    margin-bottom: 30px;

}




.tab-panel--product,
.p-card_productSwiper {
    padding-left: 20px;
    padding-right: 20px;

}

.productSwiper {
    padding-bottom: 70px;
}

@media (min-width: 1440px) {


    // .tab-panel--product,
    // .p-card_productSwiper {
    //     padding-left: calc(50% - 700px);    
    // }
}

@media(max-width:$grid-breakpoints-lg) {
    .tab__list {
        justify-content: left;
        overflow: auto;

        button {
            white-space: nowrap;
        }
    }
}

@media(max-width:$grid-breakpoints-md) {
    .productSwiper {
        padding-bottom: 0px;
    }

    .swiper-pagination {
        display: none !important;
    }
}

.tab-panel--product {
    min-height: 450px;
    position: relative;

    @media(max-width:$grid-breakpoints-xl) {
        min-height: 400px;
    }

    @media(max-width:$grid-breakpoints-sm) {
        min-height: 300px;
    }

    .info {
        position: absolute;
        top: 40%;
        transform: translate(-50%, -50%);
        left: 50%;
        margin-bottom: 30px;
        padding: 40px 30px;

    }
}