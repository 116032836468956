.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.tab__list {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.tab-panel--product,
.p-card_productSwiper {
  padding-left: 20px;
  padding-right: 20px;
}

.productSwiper {
  padding-bottom: 70px;
}

@media (max-width: 992px) {
  .tab__list {
    justify-content: left;
    overflow: auto;
  }
  .tab__list button {
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .productSwiper {
    padding-bottom: 0px;
  }

  .swiper-pagination {
    display: none !important;
  }
}
.tab-panel--product {
  min-height: 450px;
  position: relative;
}
@media (max-width: 1240px) {
  .tab-panel--product {
    min-height: 400px;
  }
}
@media (max-width: 576px) {
  .tab-panel--product {
    min-height: 300px;
  }
}
.tab-panel--product .info {
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin-bottom: 30px;
  padding: 40px 30px;
}